import { SplitClient, SplitFactory } from '@splitsoftware/splitio-react';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useMemo, useState } from 'react';

const USER_EMAIL_NAME = process.env.REACT_APP_USER_EMAIL_NAME;
export const ACCOUNT_ID_NAME = 'account-id';
const SPLIT_API_KEY = process.env.REACT_APP_SPLIT_API_KEY;
const initialKey = Cookies.get(USER_EMAIL_NAME) || 'user';

const SPLIT_CONFIG = {
  startup: {
    readyTimeout: 10
  },
  core: { authorizationKey: SPLIT_API_KEY, key: initialKey },
  sync: {
    impressionsMode: 'NONE'
  }
};

const SplitConfigContext = React.createContext();

// User traffic feature flag
export const OPERATIONS_OVERALL = 'operations-overall';
export const WEBAPP_BETA_SWITCH = 'webapp-beta-switch';
export const WEBAPP_V2_SET_REGION = 'webapp-v2-set-region';

// Account traffic feature flags
// ** Add new account-level feature flag here **

export const SplitConfigProvider = ({ children }) => {
  const [key, setKey] = useState(initialKey);

  const setKeyAndCookie = useCallback(
    ({ userEmail, accountId }) => {
      Cookies.set(USER_EMAIL_NAME, userEmail);
      Cookies.set(ACCOUNT_ID_NAME, accountId);
      setKey(userEmail);
    },
    [setKey]
  );

  const removeKeyAndCookie = () => {
    Cookies.remove(USER_EMAIL_NAME);
    Cookies.remove(ACCOUNT_ID_NAME);
    setKey('user');
  };

  const value = useMemo(() => {
    return { setKeyAndCookie, removeKeyAndCookie };
  }, [setKeyAndCookie]);

  return (
    <SplitConfigContext.Provider value={value}>
      <SplitFactory config={SPLIT_CONFIG} updateOnSdkTimedout>
        <SplitClient splitKey={key}>{children}</SplitClient>
      </SplitFactory>
    </SplitConfigContext.Provider>
  );
};

SplitConfigProvider.defaultProps = {
  children: null
};

SplitConfigProvider.propTypes = {
  children: PropTypes.node
};

export const useSplitConfig = () => {
  return useContext(SplitConfigContext);
};
