import { useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useHandleInvalidToken } from '../../../hooks';
import { publicRoutes, allUnauthorisedRoutes } from '../../../utils/routes';
import { SESSION_DATA_QUERY, EXTERNAL_SHARE_DATA_QUERY } from './queries';
import { PATHS, MEASUREMENT_SYSTEMS } from '../../../utils/constants';
import getSharedSiteParams from './getSharedSiteParams';

const routesWithoutToken = [...publicRoutes, ...allUnauthorisedRoutes];

const useFetchData = () => {
  const { pathname } = useLocation();
  const { externalShareId, sharedToId } = getSharedSiteParams(pathname);
  const { checkInvalidTokenError } = useHandleInvalidToken(routesWithoutToken);
  const isSharedSite = pathname?.startsWith(PATHS.EXPLORE_SHARE) && externalShareId && sharedToId;

  const [
    fetchSession,
    {
      data: { userByAccountId: userData, readAccountByContext: accountDataV2 } = {
        userByAccountId: {
          id: '',
          email: '',
          accountId: '',
          displayName: '',
          account: {
            id: '',
            bannerLogoStorageId: '',
            bannerLogoStorageUrl: '',
            brandingColor: '',
            brandingStatus: false,
            name: ''
          },
          measurementSystem: MEASUREMENT_SYSTEMS.METRIC
        },
        readAccountByContext: {
          id: '',
          usesApplicationCredentials: null,
          branding: {
            color: '',
            isActive: false,
            hasSkandWatermark: false,
            reportBannerFileId: null,
            webappBannerFileId: null,
            webappLogoFileId: null,
            reportBannerFile: null,
            webappBannerFile: null,
            webappLogoFile: null
          }
        }
      }
    }
  ] = useLazyQuery(SESSION_DATA_QUERY, {
    fetchPolicy: 'cache-and-network',
    onError: (e) => {
      checkInvalidTokenError(e);
    }
  });

  const [
    fetchExternalShare,
    { data: { externalShareByIdAndSharedToId: externalShareData } = {} }
  ] = useLazyQuery(EXTERNAL_SHARE_DATA_QUERY, {
    variables: { externalShareId, sharedToId },
    fetchPolicy: 'cache-and-network',
    onError: (e) => {
      checkInvalidTokenError(e);
    }
  });

  useEffect(() => {
    if (isSharedSite) {
      fetchExternalShare();
    } else {
      fetchSession();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchSession, pathname]);

  return { userData, accountDataV2, externalShareData };
};

export default useFetchData;
